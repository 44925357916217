import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { CSSProperties } from "react";
import NumberFormat from "react-number-format";
import digitsOnly from "../utils/digitsOnly";
import {t} from "i18next";

export const columnStyle: CSSProperties = { 
  whiteSpace: "pre-wrap", 
  overflow: "hidden", 
  textOverflow: "ellipsis" 
};

export const largeColumnStyle: CSSProperties = {
	display: "flex",
	width: "100%", 
	height: "100%", 
	padding: "5px 0"
}

const innerStyle: CSSProperties = {
  display: "flex",
}

export function Column(
  field: string,
  width: number,
  style: CSSProperties,
  tDictionaryKey?: string,
) {
  return { 
    field, headerName: t(`auction.table_columns.${field}`), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={tDictionaryKey ? t(`${tDictionaryKey}.${params.row[field]}`) as string : params.row[field]}>
        <div style={style}>
          <div style={innerStyle}>
            {tDictionaryKey 
              ? t(`${tDictionaryKey}.${params.row[field]}`) 
              : params.row[field]}
            </div>
        </div>
      </Tooltip>
    ),
  }
}

export function CompanyTitleColumn(
  width: number,
  style: CSSProperties
) {
  return { 
    field: "companyTitle", headerName: t("auction.table_columns.company.title"), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={params.row.company.title}>
        <div style={style}>
          <div style={innerStyle}>{params.row.company.title}</div>
        </div>
      </Tooltip>
    ),
  }
}

export function CompanyItnColumn(
  width: number,
  style: CSSProperties
) {
  return { 
    field: "companyItn", headerName: t("auction.table_columns.itn"), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={digitsOnly(params.row.itn)}>
        <div style={style}>
          <div style={innerStyle}>{digitsOnly(params.row.itn)}</div>
        </div>
      </Tooltip>
    ),
  }
}

export function GrossColumn(
  width: number,
  style: CSSProperties
) {
  return { 
		field: "gross", headerName: t("auction.table_columns.gross"), width, 
  	renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<Tooltip title={params.row?.gross}>
				<div style={style}>
					<NumberFormat 
						value={params.row?.gross} 
						thousandsGroupStyle="thousand"
						decimalSeparator="."
						displayType="text"
						type="text"
						thousandSeparator={" "}
						allowNegative={false} 
					/>
				</div>
			</Tooltip>
		),
	}
}

export function DateColumn(
  field: string,
  width: number,
  style: CSSProperties
) {
  return { 
    field, headerName: t(`auction.table_columns.${field}`), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={params.row[field].replace('T', " ") ?? params.row[field]}>
        <div style={style}>
          <div style={innerStyle}>{params.row[field].replace('T', " ") ?? params.row[field]}</div>
        </div>
      </Tooltip>
    ),
  }
}

export function OwnerNameColumn(
  width: number,
  style: CSSProperties
) {
  return { 
    field: "ownerName", headerName: t("auction.table_columns.ownerName"), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={params.row.ownerData.name}>
        <div style={style}>
          <div style={innerStyle}>{params.row.ownerData.name}</div>
        </div>
      </Tooltip>
    ),
  }
}

export function AuthoritiesColumn(
  width: number,
  style: CSSProperties
) {
  return { 
    field: "authorities", headerName: t("auction.table_columns.authorities"), width,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <Tooltip title={t(`authority.${params.row.authorities[params.row.authorities.length - 1]}`) as string}>
        <div style={style}>
          <div style={innerStyle}>
            {t(`authority.${params.row.authorities[params.row.authorities.length - 1]}`)}
          </div>
        </div>
      </Tooltip>
    ),
  }
}
