import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../common/Container";
import { useSnack } from "../../../utils/useSnack";
import { useSelector } from "react-redux";
import { State } from "../../../redux/store";
import { Participant } from "../../../types/participant";
import { Auction } from "../../../types/auction";
import { auctionService } from "../../../api/services/auction";
import { Company } from "../../../types/company";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { AuctionType } from "../../../utils/enums";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import { Column, columnStyle, largeColumnStyle, CompanyTitleColumn, GrossColumn } from "../../../common/Columns";

export default function Auctions({
  title, auctionPath, auctionsEndpoint, auctionType
}: {
  title: string;
	auctionPath: string;
	auctionsEndpoint: string;
	auctionType?: AuctionType;
}) {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const location = useLocation();
  const navigate = useNavigate();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;

  function onCreateAuction() {
    navigate(`/account-${auctionPath}/create`);
  }

  function goToAuctionDetails(id: string, participant: Participant | null) {
    if (participant) { navigate(`/account-auctions-participated/${id}`); return }
    navigate(`/account-${auctionPath}/${id}`)
  }

  const [auctions, setAuctions] = useState<Auction[]>([]);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [totalElements, setTotalElements] = useState(0);

  async function getAuctions(text: string) {
    if (!company) { return }

		try {
			setLoading(true);
      const response = await auctionService
				.getAuctions(company.id, text, page, pageSize, auctionsEndpoint, auctionType);

			if (response.status === "success" && response.data) {
				setAuctions(response.data.data);
				setTotalElements(response.data.totalElements);
			}
			else { snack("Ошибка при получении аукционов", "error");}
    }
    catch (err) { snack("Ошибка при получении аукционов", "error");}
		finally { setLoading(false) }
	}

  const search = (text: string) => getAuctions(text);
	const onClearSearch = () => {setSearchText("");	search("")};
	const onPageChange = (newPage: number) => setPage(newPage);
	const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};

  useEffect(() => {
		if (company) getAuctions(searchText);
	}, [company, location.pathname, page, pageSize]);

	const isActualOrParticipated = ["actual", "participated"].includes(auctionsEndpoint);

	function chooseDictionaryFields() {
		if (isActualOrParticipated) return [
			Column("auctionType", 150, columnStyle,"auction_type"),
			Column("state", 120, columnStyle,"auction_state"),
		]
		else return [
			Column("state", 120, columnStyle,"auction_state"),
		];
	}

  return (
    <Box>
      <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        </Box>

        <Grid item marginBottom={2} container spacing={2} alignItems={"center"}>
					<Grid item xs={12} sm={auctionType ? 4 : 9} md={auctionType ? 7 : undefined} lg={9}>
						<TextField fullWidth color="primary" variant="outlined" size="small"
							label={t("auctions_page.search_auction")}
							value={searchText} onChange={e => setSearchText(e.target.value)}
							onKeyPress={e => {if (e.key === "Enter") { search(searchText) } }}
							InputProps={{
								endAdornment: searchText ? (
									<IconButton size="small" onClick={onClearSearch}>
										<ClearIcon />
									</IconButton>
								) : undefined
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={auctionType ? 2 : undefined} lg={auctionType ? 1 : undefined}>
						<Button fullWidth color="primary" variant="contained"
							onClick={() => search(searchText)}
						>
							{t("auctions_page.search")}
						</Button>
					</Grid>
					{auctionType
						? <Grid item xs={12} sm={5} md={3} lg={2}>
								<Button fullWidth color="primary" variant="contained"
									onClick={onCreateAuction}>
									<span style={{ whiteSpace: 'nowrap' }}>{t("auctions_page.create")}</span>
								</Button>
							</Grid>
						: null
					}
				</Grid>

        <Box>
          <DataGrid
						components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
            sx={{
              height: 600,
              "& .MuiDataGrid-cell": {
                whiteSpace: "pre-wrap"
              },
            }}
						columnBuffer={isActualOrParticipated ? 8 : 7} // Columns length
            rows={auctions}
            columns={[
							...chooseDictionaryFields(),
              Column("participantCount", 100, columnStyle),
              Column("bidCount", 125, columnStyle),
              Column("auctionNo", 140, { ...columnStyle, ...largeColumnStyle }),
              Column("title", 165, { ...columnStyle, ...largeColumnStyle }),
              Column("description", 165, { ...columnStyle, ...largeColumnStyle }),
              CompanyTitleColumn(165, columnStyle),
              GrossColumn(200, columnStyle)
            ]}
            onRowClick={(rowData) => goToAuctionDetails(rowData.row.id, rowData.row.participant)}
            page={page} onPageChange={onPageChange}
            pageSize={pageSize} onPageSizeChange={onPageSizeChange}
            rowCount={totalElements} rowsPerPageOptions={[4, 8, 16]}
            loading={loading} paginationMode="server"
            density="comfortable" style={{ whiteSpace: "pre-wrap" }}
          />
        </Box>
      </Container>
    </Box>
  )
}
